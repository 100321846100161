// @flow
import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetPatientCareGapsQuery, useUpdateCareGapsMutation } from 'api/api';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { showToastMsg } from 'features/toast-message-slice';
import { useDispatch } from 'react-redux';

const actions = ['Document', 'Refer', 'Refuse', 'Error'];
const actionColors = [
  '!bg-success-main/40 !text-black',
  '!bg-mediumPurple/40 !text-black',
  '!bg-error-main/40 !text-black',
  '!bg-error-main/40 !text-black'
];

const CareGaps = () => {
  const { user } = useSelector((state) => state.user);
  const currentOrganization = useSelector((state) => state.currentOrganization);
  const { patientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data,
    error: dataError,
    isLoading
  } = useGetPatientCareGapsQuery(patientId);
  const [updateStatus] = useUpdateCareGapsMutation();
  const [careGaps, setCareGaps] = useState(data);

  // Dialog state
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCareGapId, setSelectedCareGapId] = useState(null);
  const [note, setNote] = useState('');

  const getColorForStatus = (status) => {
    const index = actions.indexOf(status);
    return actionColors[index] || '';
  };

  const handleOpenDialog = (careGapId, status) => {
    setSelectedStatus(status);
    setSelectedCareGapId(careGapId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStatus('');
    setSelectedCareGapId(null);
    setNote('');
  };

  const handleSubmitStatusChange = () => {
    handleStatusChange(selectedCareGapId, selectedStatus, note);
    handleCloseDialog();
  };

  const handleStatusChange = async (careGapId, status, note = '') => {
    const originalCareGaps = [...careGaps];
    try {
      const selectedCareGap = careGaps.find(
        (careGap) => careGap._id === careGapId
      );

      setCareGaps((prev) => [{ ...selectedCareGap, status, note }, ...prev]);
      await updateStatus({
        patientId,
        organizationId: currentOrganization._id,
        careGapId,
        status,
        note
      }).unwrap();
    } catch (error) {
      console.log('xxerror', error);
      setCareGaps(originalCareGaps);
      dispatch(
        showToastMsg({
          open: true,
          message: error?.data?.errorMessage || 'Update failed',
          level: 'error',
          duration: 5000
        })
      );
    }
  };

  useEffect(() => {
    if (currentOrganization && !currentOrganization?.config?.enableCareGaps) {
      navigate(`/patient/${patientId}`);
    }
  }, [patientId, currentOrganization]);

  useEffect(() => {
    if (data) {
      setCareGaps(data);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="flex justify-center p-3 min-h-[300px] w-100">
        <CircularProgress />
      </div>
    );
  }

  if (dataError) {
    return (
      <div className="flex justify-center p-3 w-100 min-h-[300px]">
        <Typography variant="body1" className="text-error-dark">
          Error: {dataError?.message}
        </Typography>
      </div>
    );
  }

  if (!careGaps || (careGaps && careGaps.length === 0)) {
    return (
      <div className="flex justify-center p-3 w-100 min-h-[300px]">
        <Typography variant="body1">No Care gaps available</Typography>
      </div>
    );
  }

  return (
    <Container
      maxWidth={false}
      className="!pl-[86px] sm:!pl-[193px] overflow-x-hidden"
    >
      <div className="grid my-3 gap-y-2">
        {careGaps
          .filter((careGap) => careGap.exposeOnCompendium)
          .map((careGap) => (
            <Paper key={careGap._id} className="grid p-3 gap-y-2">
              <Typography variant="h2">
                {careGap.measureOnCompendium}
              </Typography>
              <Typography variant="body1">
                {careGap.messageOnCompendium}
              </Typography>
              {!careGap.status ? (
                <div className="grid grid-flow-col gap-x-2">
                  {actions.map((action, i) => (
                    <Button
                      className={actionColors[i]}
                      variant="contained"
                      key={action}
                      onClick={() => handleOpenDialog(careGap._id, action)}
                    >
                      {action}
                    </Button>
                  ))}
                </div>
              ) : (
                <>
                  <Typography variant="body1">
                    Marked as <strong>{careGap.status}</strong> by{' '}
                    <strong>{`${user.firstName} ${user.lastName}`}</strong> on{' '}
                    {careGap.updatedDt
                      ? format(new Date(careGap.updatedDt), 'yyyy-MM-dd')
                      : format(new Date(), 'yyyy-MM-dd')}
                  </Typography>
                  {careGap.note && (
                    <Typography variant="body1">
                      <strong>Note: </strong>
                      {careGap.note}
                    </Typography>
                  )}
                </>
              )}
            </Paper>
          ))}
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: { width: '100%', maxWidth: '500px' }
        }}
      >
        <DialogTitle>{selectedStatus} Note</DialogTitle>
        <DialogContent>
          <TextField
            label="Note (optional)"
            fullWidth
            multiline
            minRows={3}
            margin="normal"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmitStatusChange}
            variant="contained"
            className={getColorForStatus(selectedStatus)}
          >
            {selectedStatus}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CareGaps;
